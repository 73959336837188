.ls-1{
    letter-spacing: -0.01em;
}
.ls-2{
    letter-spacing: -0.02em;
}
.ls-3{
    letter-spacing: -0.03em;
}
.ls-4{
    letter-spacing: -0.04em;
}